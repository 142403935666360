<script lang="ts" setup>
import { ref } from "vue";
import type { UnitMass } from "~/types/onboarding/registrationParams";

interface Props {
  kg: string | null;
  lb: string | null;
  unitMass: UnitMass;
  kgInvalid?: boolean;
  lbInvalid?: boolean;
  errorMessage?: string;
  disableAutoFocus?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "update:kg", value: string | null): void;
  (e: "update:lb", value: string | null): void;
  (e: "update:unitMass", value: UnitMass): void;
  (e: "submit"): void;
  (e: "blur"): void;
}
const emit = defineEmits<Emits>();

function isUnitSelected(unit: UnitMass) {
  return props.unitMass === unit;
}

function updateWeight(val: string) {
  if (props.unitMass === "kg") {
    emit("update:kg", val);
  } else {
    emit("update:lb", val);
  }
}

const numberInput = ref<HTMLInputElement>();

function setFocus() {
  void nextTick(() => {
    if (numberInput.value) {
      numberInput.value.focus();
    }
  });
}
onMounted(() => {
  if (!props.disableAutoFocus) {
    setFocus();
  }
});

watch(() => props.unitMass, setFocus);

function submitOnEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emit("submit");
  }
}

const isInvalid = computed(() => {
  return isUnitSelected("kg") ? props.kgInvalid : props.lbInvalid;
});
</script>

<template>
  <div>
    <div class="flex gap-3">
      <div class="flex w-full justify-center text-neutral-600">
        <input
          id="weightInput"
          ref="numberInput"
          inputmode="decimal"
          :autofocus="!disableAutoFocus"
          :value="isUnitSelected('kg') ? kg : lb"
          class="min-w-0 rounded-l-lg border-2 p-4 text-xl focus-visible:ring-1"
          :class="[
            isInvalid
              ? 'border-yz-red-danger ring-yz-red-danger focus-visible:border-yz-red-danger focus-visible:ring-yz-red-danger'
              : 'border-neutral-300 focus-visible:border-delight-blue-300 focus-visible:outline-none focus-visible:ring-delight-blue-300',
          ]"
          type="number"
          @paste.prevent
          @input="updateWeight(($event.target as HTMLInputElement).value)"
          @keydown="submitOnEnter"
          @blur="emit('blur')"
        />
        <span
          class="-z-10 flex aspect-square items-center rounded-r-lg border-2 border-l-0 border-neutral-300 bg-neutral-100 p-4 align-middle"
        >
          {{ isUnitSelected("kg") ? $t("registration.unit.kg") : $t("registration.unit.lb") }}
        </span>
      </div>
    </div>

    <div class="mt-5 flex justify-center gap-3">
      <button
        id="unitKg"
        class="rounded-full border-2 bg-white px-6 py-2.5 shadow"
        :class="[isUnitSelected('kg') ? 'border-delight-blue-300' : 'border-transparent']"
        @click="$emit('update:unitMass', 'kg')"
      >
        {{ $t("registration.unit.kg") }}
      </button>
      <button
        id="unitLb"
        class="rounded-full border-2 bg-white px-6 py-2.5 shadow"
        :class="[isUnitSelected('lb') ? 'border-delight-blue-300' : 'border-transparent']"
        @click="$emit('update:unitMass', 'lb')"
      >
        {{ $t("registration.unit.lb") }}
      </button>
    </div>
    <div class="mt-3">
      <div class="w-full text-center text-yz-red-danger">&nbsp;{{ errorMessage }}</div>
    </div>
  </div>
</template>
